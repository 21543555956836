//@ts-ignore
import React, { useEffect, useRef, useState, useContext } from "react";
import * as d3 from "d3";
import { customAlphabet } from 'nanoid'
import { ColorSchemeManager } from "./colorSchemeManager";
import { MapLinkType, MapNodeType, ColorScheme } from "../../types/interfaces";
import $ from 'jquery'
import { Sleep } from '../utils/sleep'
import CamContext from '../utils/camelotContext'
import Camelot from '../utils/camelot'

const nanoid = customAlphabet('1234567890abcdef', 10)

let svg: any = null
let zoom: any
let simulation: any
const collisionRadius = 160

export const localData = { nodes: new Array<MapNodeType>(), links: new Array<MapLinkType>() }

const MindMap = ({ mapData, colorScheme, visible }) => {

    const context = useContext(CamContext)

    const ref = useRef<HTMLHeadingElement>(null)
    const schemeRef = useRef(colorScheme);
    const nodeRef = useRef(null)
    const changeMade = useRef('')

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [showZoomControl, setShowZoomControl] = useState(false)
    const [selectedNode, setSelectedNode] = useState(null)
    const [message, setMessage] = useState('')
    // const [currentColorScheme, setCurrentColorScheme] = useState(colorScheme)

    // const [localData, setLocalData] = useState({ nodes: [], links: [] })

    let links: any
    let groups: any
    let parent: any

    const saveNodeText = (id: string, text: string) => {
        const node: MapNodeType = localData.nodes.find((x: MapNodeType) => x.id === id)!
        if (node) {
            node.name = text
        }
    }

    const getContainerSize = (currentNode: any, eNode: any, level: string) => {

        const tspans = d3.select(currentNode).select('text').selectAll('tspan').nodes()

        if (tspans.length === 0) {
            return
        }

        // 60 and 40

        const height = level === '0' ? tspans.length * 20 : tspans.length * 13

        let calcdHeight = height

        let location = '-.6em'

        if (level === '0') {
            if (height < 60) {
                calcdHeight = 60
            }
        }
        else {
            if (height < 40) {
                calcdHeight = 40
            }
        }

        location = tspans.length === 1 ? '0em' : `-${tspans.length * 0.375}em`

        return {
            textPos: location,
            height: calcdHeight
        }

    }

    const handleLinkRemoval = (id: string, ids: string[] = []): string[] => {

        // const list: string[] = [...ids]

        const links: any[] = localData.links.filter((x: any) => x.source.id === id)

        if (links.length > 0) {
            links.forEach((link: any) => {
                ids.push(link.target.id)
                return handleLinkRemoval(link.target.id, ids)
            })
        }

        return ids
    }

    const setTextLocation = (currentNode: any, location: string) => {
        const text = d3.select(currentNode).select('text')
        text.attr('y', location)

        const tNodes = d3.select(currentNode)
            .selectAll('tspan')
            .nodes()

        if (tNodes.length > 0) {
            tNodes.forEach((n: any) => {
                n.setAttribute('y', location)
            })
        }
    }

    const handleReturnKey = (currentNode: any) => {
        const level = d3.select(nodeRef.current).attr('level')
        const currentStroke = d3.select(nodeRef.current).attr('stroke')
        const id = d3.select(nodeRef.current).attr('id')
        const eNode = d3.select(nodeRef.current).select('ellipse').node()
        const text = d3.select(nodeRef.current).select('text')

        const bgcolor = ColorSchemeManager.getColor(schemeRef.current, Number(level))
        const tcolor = pickTextColorBasedOnBgColorSimple(bgcolor)
        text.style('fill', tcolor)
        text.style('stroke', null)
        text.style('stroke-width', null)
        text.style('paint-order', null)
        text.style('stroke-linejoin', null)
        wrapTextNode(text)
        const textValue = getWords(text, false).join(' ')
        saveNodeText(id, textValue)
        const newSize = getContainerSize(nodeRef.current, eNode, level)
        setEllipseHighlight(eNode, Number(level), currentStroke, newSize)
        setTextLocation(nodeRef.current, newSize!.textPos)
        changeMade.current = ''
        nodeRef.current = null

    }

    const setTextEditMode = (text: any) => {
        text.style('fill', 'black')
        text.style('stroke', 'white')
        text.style('stroke-width', '0.5em')
        text.style('paint-order', 'stroke')
        text.style('stroke-linejoin', 'round')
    }

    const handleKeyDown = (e: any) => {

        e.stopImmediatePropagation()

        if (nodeRef.current) {

            if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode === 32 || e.keyCode === 37 || e.keyCode === 13 || e.keyCode === 8 || e.keyCode === 27) {

                const level = d3.select(nodeRef.current).attr('level')
                const currentStroke = d3.select(nodeRef.current).attr('stroke')
                const id = d3.select(nodeRef.current).attr('id')
                const eNode = d3.select(nodeRef.current).select('ellipse').node()
                const text = d3.select(nodeRef.current).select('text')

                switch (e.keyCode) {
                    case 13:
                        // return key
                        handleReturnKey(nodeRef.current)
                        break
                    case 27:
                        if (changeMade.current === id) {
                            const node: MapNodeType = localData.nodes.find((x: MapNodeType) => x.id === id)!
                            text.text(node.name)
                            wrapTextNode(text)
                        }
                        setEllipseHighlight(eNode, Number(level), currentStroke)
                        nodeRef.current = null
                        changeMade.current = ''
                        break
                    case 8:
                        if (e.shiftKey) {
                            if (confirm('Are you sure you want to PERMANENTLY DELETE this node and all its children?')) {
                                const list = [id]
                                const linkIds = handleLinkRemoval(id, list)
                                const newLinks = localData.links.filter((x: any) => !linkIds.includes(x.source.id) && !linkIds.includes(x.target.id))
                                const newNodes = localData.nodes.filter((x: any) => !linkIds.includes(x.id))

                                // console.log('newNodes', newNodes.length)
                                // console.log('newLinks', newLinks.length)
                                // console.log('existingNodes', localData.nodes.length)
                                // console.log('existingLinks', localData.links.length)

                                localData.nodes = [...newNodes] as Array<MapNodeType>
                                localData.links = [...newLinks] as Array<MapLinkType>

                                // console.log('updatedNodes', localData.nodes.length)
                                // console.log('updatedLinks', localData.links.length)

                                parent = d3.select('svg').select('g')

                                buildGraph(parent)
                                activateSimulation()
                                nodeRef.current = null
                                changeMade.current = ''
                            }
                        }
                        else {
                            const tNodes = d3.select(nodeRef.current)
                                .selectAll('tspan')
                                .nodes()


                            if (tNodes.length > 0) {
                                let spanText = ''
                                tNodes.forEach((n: any) => {
                                    // console.log(n.innerHTML)
                                    if (spanText === '') {
                                        spanText += n.innerHTML
                                    }
                                    else {
                                        spanText += ` ${n.innerHTML}`
                                    }
                                })
                                text.text(spanText.slice(0, -1))
                                setTextEditMode(text)
                            }
                            else {
                                text.text(text.text().slice(0, -1))
                            }
                        }
                        changeMade.current = id
                        break;
                    default:
                        if (text.text() === 'New Node' || text.text() === 'New Mind Map') {
                            // https://stackoverflow.com/questions/15500894/background-color-of-text-in-svg
                            setTextEditMode(text)
                            //stroke:white; stroke-width:0.5em; fill:black; paint-order:stroke; stroke-linejoin:round
                            text.text(e.key)
                        }
                        else {

                            const tNodes = d3.select(nodeRef.current)
                                .selectAll('tspan')
                                .nodes()

                            if (tNodes.length > 0) {
                                let spanText = ''
                                tNodes.forEach((n: any) => {
                                    // console.log(n.innerHTML)
                                    if (spanText === '') {
                                        spanText += n.innerHTML
                                    }
                                    else {
                                        spanText += ` ${n.innerHTML}`
                                    }
                                })
                                text.text(spanText + e.key)
                                setTextEditMode(text)
                            }
                            else {
                                text.text(text.text() + e.key)
                            }
                        }
                        changeMade.current = id
                        break;
                }
            }

        }
    }

    const handleMouseUp = (e) => {
        e.stopPropagation()
        // console.log('mouse up')
    }

    const resizeNodes = (parent: any) => {

        const nodes = parent.selectAll('.node').nodes()

        nodes.forEach((node: any) => {
            const level = d3.select(node).attr('level')
            const eNode = d3.select(node).select('ellipse').node()
            const text = d3.select(node).select('text')
            const newSize = getContainerSize(node, eNode, level)
            setEllipseHighlight(eNode, Number(level), 'notblack', newSize)
            setTextLocation(node, newSize!.textPos)
        })
    }

    useEffect(() => {

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mouseup', handleMouseUp);
        };

    }, [])

    useEffect(() => {

        // console.log('loading map data from source')

        if (!mapData) {
            d3.selectAll("#maproot svg").remove();
            // setShowZoomControl(false)
            return
        }

        // console.log('map data', mapData)

        localData.nodes = [...mapData.nodes] as Array<MapNodeType>
        localData.links = [...mapData.links] as Array<MapLinkType>

        // setShowZoomControl(false)

        setUpSimulation()

        buildGraph(parent)

        resizeNodes(parent)

        activateSimulation()

        // setShowZoomControl(true)

    }, [mapData]);

    const setEllipseFill = () => {
        const gees = d3.selectAll('.node').selectAll('ellipse')
            .style('fill', (d: any) => ColorSchemeManager.getColor(schemeRef.current, d.level)!)
    }

    const setTextColor = () => {
        d3.selectAll('.node').selectAll('text')
            .style('fill', (d: any) => {
                const bgcolor = ColorSchemeManager.getColor(schemeRef.current, d.level)
                return pickTextColorBasedOnBgColorSimple(bgcolor)
            })
            .style('stroke', (d: any) => null)
            .style('stroke-width', (d: any) => null)
            .style('paint-order', (d: any) => null)
            .style('stroke-linejoin', (d: any) => null)
        d3.selectAll('.node').selectAll('text').selectAll('tspan')
            .style('fill', (d: any) => {
                const bgcolor = ColorSchemeManager.getColor(schemeRef.current, d.level)
                return pickTextColorBasedOnBgColorSimple(bgcolor)
            })
            .style('stroke', (d: any) => null)
            .style('stroke-width', (d: any) => null)
            .style('paint-order', (d: any) => null)
            .style('stroke-linejoin', (d: any) => null)
    }

    useEffect(() => {
        schemeRef.current = colorScheme

        setEllipseFill()

        // console.log('setting text color')

        setTextColor()

    }, [colorScheme])

    const backgroundClass = () => {
        return `react-target animate__animated animate__fadeIn`
    }

    const showMap = () => {
        if (visible) {
            return 'showMap'
        }
        return 'hideMap'
    }

    function handleZoom(e) {
        d3.select('svg g')
            .attr('transform', e.transform);
    }

    const createNode = (name: string, level: number, positionX: number = 0, positionY: number = 0): MapNodeType => {
        return {
            id: nanoid(8),
            name,
            level,
            x: positionX,
            y: positionY
        }
    }

    const createLink = (source: string, target: string, level: number): MapLinkType => {
        return {
            source,
            target,
            level
        }
    }

    const setUpSimulation = () => {

        d3.selectAll("#maproot svg").remove();

        svg = d3.select('#maproot').append('svg')
            .attr('width', '100%')
            .attr('viewBox', `0 0 ${width} ${height}`)
            .attr("preserveAspectRatio", "xMinYMin meet")

        zoom = d3.zoom()
            .on('zoom', handleZoom);

        d3.select('svg')
            .call(zoom)
            .on("dblclick.zoom", null)

        simulation = d3.forceSimulation()
            .force('link', d3.forceLink().id((d: any) => d.id).distance(250))
            .force('center', d3.forceCenter(width / 2, height / 2))
            // .force("x", d3.forceX(width / 2))
            // .force("y", d3.forceY(height / 2))
            .force("charge", d3.forceManyBody().strength(-500))
            .force('collision', d3.forceCollide().radius(collisionRadius))
            .alphaDecay(0.2)

        simulation.stop()

        parent = d3.select('svg').append('g')

    }


    const getWords = (text: any, reverse: boolean = true): Array<string> => {

        let words: Array<string> = []

        let spans = text.selectAll('tspan').nodes()

        if (spans.length > 1) {
            if (reverse) {
                spans.reverse().forEach((span: any) => {
                    words.push(span.textContent)
                })
            }
            else {
                spans.forEach((span: any) => {
                    words.push(span.textContent)
                })
            }
        }
        else {
            if (reverse) {
                words = text.text().split(/\s+/).reverse()
            }
            else {
                words = text.text().split(/\s+/)

            }

        }

        return words

    }

    const wrapTextNode = (text: any) => {

        text.each(function () {

            let text = d3.select(this)

            let width = text.attr('level') === '0' ? 225 : 125

            const words = getWords(text)

            // console.log(text.text(), words)

            // console.log('words', words)

            let word: string | undefined,
                line: Array<any> = [],
                lineNumber = 0,
                lineHeight = 1.1, // ems
                x = text.attr("x"),
                y = text.attr("y"),
                dy = 0, //parseFloat(text.attr("dy")),
                tspan: any = text.text(null)
                    .append("tspan")
                    .attr("x", x)
                    .attr("y", y)
                    .attr("dy", dy + "em");

            // Implementation Considerations

            while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text.append("tspan")
                        .attr("x", 0)
                        .attr("y", y)
                        .attr("dy", ++lineNumber * lineHeight + dy + "em")
                        .text(word);
                }
            }
        });

    }

    const handleDblClick = (d: any) => {

        // console.log('current color scheme on dblclick', colorScheme)
        const item = d.target.__data__

        // console.log(item)

        const nextLevel = item.level + 1
        let new_node: MapNodeType

        if (nextLevel > 1) {
            // calculate node new position based on parent position
            const parentNodeX = item.x;
            const parentNodeY = item.y;

            // Calculate the desired offset from the parent node's position
            const offsetX = parentNodeX > 0 ? collisionRadius : collisionRadius * -1;
            const offsetY = parentNodeY > 0 ? collisionRadius : collisionRadius * -1;

            // Calculate the position of the new node relative to the parent node
            const newNodeX = parentNodeX + offsetX;
            const newNodeY = parentNodeY + offsetY;

            new_node = createNode('New Node', nextLevel, newNodeX, newNodeY)

        }
        else {
            new_node = createNode('New Node', nextLevel)
        }

        const new_link = createLink(item.id, new_node.id!, nextLevel)

        //@ts-ignore
        localData.nodes.push(new_node)
        //@ts-ignore
        localData.links.push(new_link)

        buildGraph(parent)

        activateSimulation()

    }

    const getEllipse = (event) => {

        let ovalNode: any

        let gNode: any
        let eNode: any

        if (event.target.localName === 'ellipse') {
            eNode = event.target
            gNode = event.target.parentNode
        }
        else {
            let p = event.target.parentNode
            let g = p.parentNode

            const ellipse = d3.select(g).select('ellipse')

            if (ellipse) {
                eNode = ellipse.node()
                gNode = g
            }
        }

        return { gNode, eNode }
    }

    const calculateEllipseBorder = (level: number, color: any) => {

        const currentColor = color === 'rgb(0, 0, 0)' ? 'black' : color

        let nextWidth = 0

        if (currentColor === 'black') {
            nextWidth = 6
        }
        else {
            if (level === 0) {
                nextWidth = 4
            }
            else {
                if (level === 1) {
                    nextWidth = 2
                }
                else {
                    nextWidth = 1
                }
            }
        }

        let nextStroke = color !== "black" ? "black" : Camelot.NODE_HIGHLIGHT_COLOR

        return {
            width: nextWidth,
            stroke: nextStroke
        }

    }

    const setEllipseHighlight = (eNode: any, level: number, color: any, newSize: any = null) => {

        const ellipseInfo = calculateEllipseBorder(level, color)

        if (newSize) {
            d3.select(eNode)
                .transition().duration(100)
                .style("stroke", ellipseInfo.stroke)
                .style("stroke-width", ellipseInfo.width)
                .attr("ry", Number(newSize.height))
        }
        else {
            d3.select(eNode)
                .transition().duration(100)
                .style("stroke", ellipseInfo.stroke)
                .style("stroke-width", ellipseInfo.width)
        }

    }

    const handleClick = (event: any) => {

        event.stopImmediatePropagation()

        const nodes = getEllipse(event)

        let color = d3.select(nodes.eNode).style("stroke");

        if (color === 'rgb(0, 0, 0)') {
            color = 'black'
        }

        const level = event.target.__data__.level

        d3.selectAll('.mindmap-ellipse')
            .style("stroke", "black")
            .style("stroke-width", (d: any) => {
                switch (d.level) {
                    case 0:
                        return '4'
                    case 1:
                        return '2'
                    default:
                        return '1'
                }
            });

        setEllipseHighlight(nodes.eNode, Number(level), color)

        if (color === 'black') {
            if (nodeRef.current) {
                handleReturnKey(nodeRef.current) // need to finish up previous node
            }
            nodeRef.current = nodes.gNode
        }
        else {
            nodeRef.current = null
        }

    }

    const start = function (event) {
        if (!event.active) {
            simulation.alphaTarget(0.1).restart();
        }
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
    };

    const drag = function (event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
    };

    const end = function (event) {
        if (!event.active) {
            simulation.alphaTarget(0);
        }
        event.subject.fx = null;
        event.subject.fy = null;
    };

    const dragBehavior = d3.drag()
        .on("start", start)
        .on("drag", drag)
        .on("end", end);

    const pickTextColorBasedOnBgColorSimple = (bgColor: any, lightColor: any = Camelot.TEXT_LIGHT_COLOR, darkColor: any = Camelot.TEXT_DARK_COLOR) => {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        // return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
        //     darkColor : lightColor;
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 170) ?
            darkColor : lightColor;
    }

    const buildGraph = (parent: any) => {

        links = parent.selectAll('line')
            .data(localData.links, (d: any) => d.source.id + '-' + d.target.id)
            .join('line')
            .style('stroke', (d: any) => d.level === 1 ? '#000' : '#999')
            .style('stroke-width', (d: any) => d.level === 1 ? '3px' : '2px')

        groups = parent.selectAll('.node')
            .data(localData.nodes, (d: any) => d.id)

        groups.exit().remove()

        const groupItem = groups.enter()
            .append('g') // don't use containing groups
            .attr('class', 'node')
            .attr('level', (d: any) => d.level)
            .attr('id', (d: any) => d.id)
            .call(dragBehavior)

        groupItem.append("ellipse")
            .style('fill', (d: any) => ColorSchemeManager.getColor(schemeRef.current, d.level))
            .style('stroke', 'black') // Set the border color to black
            .style('stroke-width', (d: any) => {
                switch (d.level) {
                    case 0:
                        return '4'
                    case 1:
                        return '2'
                    default:
                        return '1'
                }
            }) // Set the border width to 1px
            .attr('rx', (d: any) => {
                if (d.level === 0) {
                    return 150
                }
                return 100
            }) // Set horizontal radius
            .attr('ry', (d: any) => {
                // console.log('setting height')
                if (d.level === 0) {
                    return 60
                }
                return 40
            })
            // .attr('draggable', false)
            .attr('class', 'mindmap-ellipse')
            .on('dblclick', (d: any) => {
                // console.log('dblclick', colorScheme)
                handleDblClick(d)
            })
            .on('click', function (d: any) {
                //@ts-ignore
                handleClick(d)
            })

        groupItem.append("title").text((d: any) => d.name)


        groupItem.append('text')
            .attr('text-anchor', 'middle') // Center the text horizontally
            .attr('y', '-.6em') // Center the text vertically
            .attr('fill', (d: any) => {
                const bgcolor = ColorSchemeManager.getColor(schemeRef.current, d.level)
                return pickTextColorBasedOnBgColorSimple(bgcolor)
            })
            .attr("id", "ellipseText")
            .attr("level", (d: any) => d.level)
            .text((d: any) => {
                return d.name;
            })
            .style("font-weight", (d: any) => {
                switch (d.level) {
                    case 0:
                        return 'bolder'
                    case 1:
                        return 'bolder'
                    default:
                        return 'normal'
                }
            })
            .style('font-size', (d: any) => {
                switch (d.level) {
                    case 0:
                        return '24px'
                    case 1:
                        return '18px'
                    default:
                        return '16px'
                }
            })
            .attr('anchor', (d: any) => d.anchor ? 1 : 0)
            .on('dblclick', (d: any) => {
                handleDblClick(d)
            })
            .on('click', function (d: any) {
                //@ts-ignore
                handleClick(d)
            })
            .call(wrapTextNode)

        groups.raise(); // bring to front   

    }

    const activateSimulation = () => {

        simulation.nodes(localData.nodes)
        simulation.force('link').links(localData.links);

        groups = parent.selectAll('.node') // make sure we are working with current nodes

        simulation.nodes(localData.nodes)
            .on('tick', () => {
                links.attr('x1', (d: any) => d.source.x)
                    .attr('y1', (d: any) => d.source.y)
                    .attr('x2', (d: any) => d.target.x)
                    .attr('y2', (d: any) => d.target.y);
                groups.attr('transform', (d: any, index: any) => {
                    return `translate(${d.x}, ${d.y})`
                })
            })

        simulation.alpha(1).restart()

    }

    // const handleZoomOut = () => {
    //     d3.selectAll('svg g')
    //         .attr('x', 0)
    //         .attr('y', 0)
    //         .transition()
    //         .call(zoom.scaleBy, 0.75)
    // }

    // const handleZoomIn = () => {
    //     d3.selectAll('svg g')
    //         .attr('x', 0)
    //         .attr('y', 0)
    //         .transition()
    //         .call(zoom.scaleBy, 1.5)
    // }

    // const handleCenterZoom = () => {
    //     if (ref.current) {
    //         const rt = ref.current!.offsetTop
    //         const rl = ref.current!.offsetLeft
    //         const rw = ref.current!.offsetWidth
    //         const rh = ref.current!.offsetHeight
    //         d3.select('svg')
    //             .transition()
    //             .call(zoom.translateTo, 0.5 * width, 0.5 * height);
    //     }
    // }

    const mapName = () => {
        if (context.diagramName) {
            return <span><span style={{ color: '#999' }}>Map Name:</span><span style={{ marginLeft: '7px' }}>{context.diagramName}</span></span>
        }
        return ''
    }

    return (
        <div className={backgroundClass()} >
            <div id="maproot" className={showMap()} ref={ref}></div>
            <div className="map-name">
                {mapName()}
            </div>
        </div>
    );
}

export default MindMap