/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import CamContext from './utils/camelotContext'
import { useNavigate, Link } from "react-router-dom";

const Navigation = ({ signOut, user, showDiagramsOnly }) => {
    const context = useContext(CamContext)
    const navigate = useNavigate();

    const formatUser = (user) => {
        // console.log('The user', user)
        return user.signInDetails.loginId.split('@')[0]
        // return ''
    }

    return (

        <div className="grid-x" style={{ marginTop: '12px' }}>
            <div className="cell small-10 medium-10 large-6" style={{ margin: '0 auto', height: '45px', borderBottom: '1px solid #999' }}>

                <div style={{ padding: '7px', float: 'left' }}>
                    <img src="/logo.png" width="150" alt="" />
                </div>

                <div className="float-right" style={{ marginTop: '7px' }}>
                    {showDiagramsOnly ?
                        <ul className="vertical medium-horizontal menu menu-wrapper">
                            <li><a href="/">Diagrams</a></li>
                            <li><a href="#" onClick={signOut}>Sign Out</a></li>
                        </ul>
                        :
                        <ul className="vertical medium-horizontal menu menu-wrapper">
                            <li><a href="/">Diagrams</a></li>
                            <li><a href="/maps">Mind Map</a></li>
                            <li><a href="/gen">Code</a></li>
                            <li><a href="#" onClick={signOut}>Sign Out</a></li>
                        </ul>
                    }
                </div>

            </div>
        </div >

    )

    // return (

    //     <div className="top-bar">
    //         <div className="top-bar-left">
    //             <ul className="dropdown menu" data-dropdown-menu>
    //                 <li className="menu-text">
    //                     <div style={{ padding: '7px', float: 'left' }}>
    //                         <img src="/logo.png" width="150" alt="" />
    //                     </div>
    //                 </li>
    //                 <li> <a href="#">Diagrams</a></li>
    //                 <li><a href="#">Mindmaps</a></li>
    //                 <li><a href="#">Gen UX</a></li>
    //             </ul>
    //         </div>
    //         <div className="top-bar-right">
    //             <ul className="menu">
    //                 <li><button className="clear button primary float-right" onClick={signOut}>Sign out {formatUser(user)}</button></li>
    //             </ul>
    //         </div>
    //     </div>
    // )

}

export default Navigation

