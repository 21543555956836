import { customAlphabet } from 'nanoid'
import { MapLinkType, MapNodeType } from '../../types/interfaces';
const nanoid = customAlphabet('1234567890abcdef', 10)

export class GenerateMapData {

    private data: any;
    private nodes: Array<MapNodeType> = [];
    private links: Array<MapLinkType> = [];

    constructor(data: any) {
        this.data = data;
    }

    drawChildren(parentNode: MapNodeType, items: Array<MapNodeType>, level: number = 0) {

        const nextLevel = level + 1;

        items.forEach((item) => {

            const newParent = this.addChildNode(parentNode, item.name, nextLevel);

            if (item.items) {
                this.drawChildren(newParent, item.items, nextLevel)
            }

        })
    }

    createNode(name: string, level: number): MapNodeType {
        return {
            id: nanoid(8),
            name,
            level
        }
    }

    createLink(parentId: string, childId: string, level: number): MapLinkType {
        return {
            source: parentId,
            target: childId,
            level
        }
    }

    addChildNode(parentNode: MapNodeType, name: string, level: number) {

        const newNode = this.createNode(name, level);

        this.nodes.push(newNode);

        const newLink = this.createLink(parentNode.id!, newNode.id!, level);

        this.links.push(newLink);

        return newNode;

    }

    generate() {
        const newNode = {
            id: nanoid(8),
            name: this.data.name,
            anchor: true,
            level: 0
        }

        this.nodes.push(newNode);

        this.drawChildren(newNode, this.data.items);

        return {
            nodes: this.nodes,
            links: this.links
        }
    }

}


