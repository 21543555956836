import React, { useState, useEffect, ReactNode } from 'react'
import { Excalidraw, exportToBlob, useHandleLibrary } from '@excalidraw/excalidraw'
// @ts-ignore
import Timer from 'react-timer-wrapper'
import type { ExcalidrawElement } from "@excalidraw/excalidraw/types/element/types";
import {
    ExcalidrawImperativeAPI, LibraryItems
} from "@excalidraw/excalidraw/types/types";

// import { GenerateComponent } from './utilities/generateComponent'
import ComponentWrapper, { ComponentWrapperProps } from './componentWrapper'
import { TargetTypes } from './enums'
import { DataManager } from '../utils/dataManager';
import Navigation from '../navigation.jsx'
import $ from 'jquery'

// https://bipankishore.github.io/resizable-panes-demo/

function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise<string | ArrayBuffer | null>((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    })
}

const ComponentBuilder = ({ signOut, user }) => {
    const [excalidrawApi, setExcalidrawApi] = useState<ExcalidrawImperativeAPI>()
    const [isSpinning, setIsSpinning] = useState(false)
    const [generatedComponent, setGeneratedComponent] = useState<ReactNode>()
    const [targetType, setTargetType] = useState(TargetTypes.Terraform)
    const [timeValue, setTimeValue] = useState(0)
    const [isVisible, setIsVisible] = useState(false)
    const [codeValue, setCodeValue] = useState('')

    useHandleLibrary({ excalidrawAPI: excalidrawApi || null });

    useEffect(() => {
        $(document).foundation();
    }, [])

    const handleGenerateComponent = async (instructions: string = '') => {
        setTimeValue(0)
        setCodeValue('')
        setIsSpinning(true)
        setGeneratedComponent('')
        try {
            if (excalidrawApi) {

                const elements = excalidrawApi.getSceneElements()

                if (elements.length === 0) {
                    setGeneratedComponent(<div className="callout alert" ><p>No elements to generate!</p></div>)
                    return
                }

                const opts = {
                    elements,
                    appState: excalidrawApi.getAppState(),
                    files: excalidrawApi.getFiles(),
                    mimeType: 'image/jpeg',
                    quality: .5
                };

                const blob = await exportToBlob(opts)

                if (blob) {

                    const image = await blobToBase64(blob)

                    const comp = await DataManager.generateCode({ image, targetType, instructions })

                    if (comp === null) {
                        setGeneratedComponent(<div className="callout warning"><p>Could not generate component!</p></div>)
                    }
                    else {
                        setIsVisible(true)
                        setCodeValue(comp as string)
                        setGeneratedComponent(`${comp}`)
                    }
                }
            }
        }
        catch (err) {
            console.log('Error occurred generating component', err)
        }
        finally {
            setIsSpinning(false)
            setTimeValue(0)
        }
    }

    const onTimerUpdate = () => {
        const val = timeValue + 1
        setTimeValue(val)
    }

    const formatTimeValue = (value: number) => {
        const minutes = Math.floor(value / 60)
        const seconds = value % 60
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    }

    const showControls = () => {
        return !isVisible ? 'grid-x invisible save-button' : 'grid-x save-button'
    }

    const generateTheComponent = () => {
        setIsVisible(false)
        handleGenerateComponent()
    }

    const addInstructions = (event: any) => {
        const instructions = event.target.previousSibling.value
        handleGenerateComponent(instructions)
    }

    const clearOutput = () => {
        const instructions = document.getElementById('instructions-text') as HTMLInputElement
        instructions.value = ''
        setGeneratedComponent(undefined)
    }

    const handleTargetTypeChange = (value: any) => {
        setTargetType(value)
    }

    const handleLibraryChange = (library: LibraryItems) => {
        // console.log('library changed', library)
    }

    return (
        <>
            <Navigation signOut={signOut} user={user} showDiagramsOnly={true} />
            <div className="reveal" id="codemodal" data-reveal>
                <h3>Generated Code</h3>
                <div id="Code" style={{ backgroundColor: '#dedede', color: '#444', padding: '10px' }}>
                    <pre><code>{codeValue}</code></pre>
                </div>
                <div className="save-button">
                    <button className="button small" data-toggle="" style={{ marginTop: '7px' }}>OK</button>
                </div>
                <button className="close-button" data-toggle="" aria-label="OK" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <Timer
                active={isSpinning}
                loop={true}
                onTimeUpdate={onTimerUpdate}
            />
            <div className="grid-x" style={{ marginTop: '7px', margin: '0px', paddingRight: '2px' }}>
                <div className="cell small-6">
                    <div className="grid-x" style={{ padding: '5px' }}>
                        <div className="cell small-6">

                            <select onChange={(event) => handleTargetTypeChange(event.target.value)} defaultValue={TargetTypes.BootstrapCSS}>
                                <option value={TargetTypes.Terraform}>Generate Terraform from Diagram</option>
                                <option value={TargetTypes.BootstrapCSS} >Generate UX from Picture</option>
                            </select>

                        </div>
                        <div className="cell small-6 clearfix save-button">
                            <button className="button small float-right" disabled={isSpinning} onClick={generateTheComponent}>
                                {isSpinning ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''} Generate Component
                            </button>
                            {isSpinning ?
                                <span style={{ marginRight: '21px', fontFamily: 'monospace', float: 'right' }}>{formatTimeValue(timeValue)}</span>
                                : ''}
                        </div>
                    </div>
                    <div className="grid-x" style={{ padding: '5px' }}>
                        <div className="cell small-12" style={{ marginTop: '0px' }}>
                            <div className="excalidraw-wrapper">
                                <Excalidraw
                                    excalidrawAPI={(api) => setExcalidrawApi(api)}
                                    onLibraryChange={handleLibraryChange}
                                    libraryReturnUrl="http://localhost:3000/gen"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cell small-6" style={{ borderLeft: '#A55640 2px solid', padding: '5px' }}>
                    <div className={showControls()}>
                        <div className="cell small-3 ">
                            <button type="button" className="button small" data-open="codemodal">View Code</button>
                            <button type="button" className="button small" style={{ marginLeft: '7px' }} onClick={clearOutput}>Clear</button>
                        </div>
                        <div className="cell small-9">
                            <textarea id="instructions-text" rows={3} className='form-control float-left' style={{ width: '90%' }} placeholder="Adjust output design conversationally" />
                            <button type="button" className="button small float-right" onClick={addInstructions}>Go</button>
                        </div>
                    </div>
                    <div className="grid-x">
                        <div className="cell small-12">
                            <div id="gen-target"></div>
                            {generatedComponent
                                ? <ComponentWrapper {...{ source: generatedComponent, target: 'gen-target' } as ComponentWrapperProps} />
                                : ''
                            }
                        </div>
                    </div>

                </div>
            </div >
        </>
    )

}

export default ComponentBuilder