import React, { useState, useEffect, useCallback } from 'react'

let interval: any

const TimeKeeper = ({ active }) => {

    const [seconds, setSeconds] = useState(0);

    const runTimer = () => {
        setSeconds(0)
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);
    }

    useEffect(() => {
        if (active === true) {
            runTimer()
        } else {
            setSeconds(0)
            clearInterval(interval)
        }

        return () => clearInterval(interval);
    }, [active])

    const formatTime = (seconds: number) => {

        const zeroPad = (num, places) => String(num).padStart(places, '0')

        // console.log(zeroPad(5, 2)); // "05"
        const minutes = zeroPad(Math.floor(seconds / 60), 2)
        const secs = zeroPad(seconds % 60, 2)
        return `${minutes}:${secs}`
    }

    return (
        <span>{formatTime(seconds)}</span>
    )
}

export default TimeKeeper