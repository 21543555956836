
//https://www.color-hex.com/color-palettes/

import { Color } from "d3"
import { ColorScheme } from "../../types/interfaces"

export const ColorSchemes: Array<ColorScheme> = [
    {
        name: 'greatwhiteshark',
        title: 'Great White Shark',
        colors: ['#e6e6e6', '#f2f2f2', '#f9f9f9', '#ffffff', '#ffffff']
    },
    {
        name: 'purplehaze',
        title: 'Purple Haze',
        colors: ['#a270ff', '#b88cff', '#cfa8ff', '#e5d4ff', '#f4f0ff']
    },
    {
        name: 'steel',
        title: 'Steel',
        colors: ['#6c757d', '#868e96', '#adb5bd', '#ced4da', '#e9ecef']
    },
    {
        name: 'mint',
        title: 'Mint',
        colors: ['#a0ffb8', '#b8ffc6', '#cfffda', '#e5ffe5', '#f4fff4']
    },
    {
        name: 'sunflower',
        title: 'Sunflower',
        colors: ['#ffde59', '#ffeb7a', '#fff4a3', '#fff9c2', '#ffffe0']
    },
    {
        name: 'pumpkin',
        title: 'Pumpkin',
        colors: ['#ff9f1a', '#ffc371', '#ffd8a8', '#ffe8cc', '#fff7e6']
    },
    {
        name: 'winter',
        title: 'Winter',
        colors: ['#a8dadc', '#c9e4e7', '#e2edf0', '#f0f6f7', '#f8fbfc']
    },
    {
        name: 'valentine',
        title: 'Valentine',
        colors: ['#f72585', '#ff70a6', '#ff94c2', '#ffb6d9', '#ffd7eb']
    },
    {
        name: 'mustard',
        title: 'Mustard',
        colors: ['#ffbe0b', '#ffd05c', '#ffe29f', '#ffedcc', '#fff8e6']
    },
    {
        name: 'sky',
        title: 'Sky',
        colors: ['#48cae4', '#90e0ef', '#ade8f4', '#caf0f8', '#e8f6f8']
    },
    {
        name: 'candycane',
        title: 'Candy Cane',
        colors: ['#ff6363', '#ff7e7e', '#ff9d9d', '#ffbcbc', '#ffd7d7']
    },
    {
        name: 'camoflage',
        title: 'Camoflage',
        colors: ['#3a7c80', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51']
    },
    {
        name: 'sunset',
        title: 'Sunset',
        colors: ['#ff6b6b', '#f4a261', '#e9c46a', '#e76f51', '#ffbe0b']
    },
    {
        name: 'carribean',
        title: 'Carribean',
        colors: ['#bb3049', '#d62828', '#f77f00', '#fcbf49', '#eae2b7']
    },
    {
        name: 'ocean',
        title: 'Ocean',
        colors: ['#065a60', '#0b8f92', '#0e9f9f', '#0fb3b3', '#0fd1ce']
    },
    {
        name: 'coral',
        title: 'Coral',
        colors: ['#ff8474', '#f9ad9d', '#fcd5ce', '#fff4f2', '#ffffff']
    },
    {
        name: 'breeze',
        title: 'Breeze',
        colors: ['#f9f9f9', '#e9ecef', '#dee2e6', '#ced4da', '#adb5bd']
    },
    {
        name: 'lavender',
        title: 'Lavender',
        colors: ['#b8c1ec', '#d9d2f3', '#e9e4f0', '#f4f1f9', '#ffffff']
    },
    {
        name: 'magenta',
        title: 'Magenta',
        colors: ['#ff0000', '#ff00bf', '#ff00ff', '#bf00ff', '#8000ff']
    },
    {
        name: 'ice',
        title: 'Ice',
        colors: ['#00b4d8', '#48cae4', '#90e0ef', '#ade8f4', '#caf0f8']
    },
    {
        name: 'moss',
        title: 'Moss',
        colors: ['#2b9348', '#55a630', '#80b918', '#aacc00', '#cce51a']
    },
    {
        name: 'trueblue',
        title: 'True Blue',
        colors: ['#03045e', '#023e8a', '#0077b6', '#0096c7', '#00b4d8']
    }

]

export class ColorSchemeManager {

    static getColorScheme(name: string = 'greatwhiteshark') {
        console.log('Retrieving color scheme', name)
        return ColorSchemes.find((scheme) => scheme.name === name)
    }

    static getColor(name: string = 'greatwhiteshark', level: number = 0) {
        // console.log('Retrieving color for scheme name', name)
        const scheme = ColorSchemes.find((scheme) => scheme.name === name)
        if (scheme) {
            return scheme.colors[level <= 4 ? level : 4]
        }
    }

    static getColorSchemes(): Array<ColorScheme> {
        return ColorSchemes.map((scheme) => scheme)
    }

}

