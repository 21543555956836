//https://stackoverflow.com/questions/3975499/convert-svg-to-image-jpeg-png-etc-in-the-browser#comment28332332_3976034

export type ReadingTypes = 'png' | 'jpeg';

export class SvgToImage {

    dataHeader: string
    svg: SVGSVGElement
    format: ReadingTypes
    quality: number
    width: number
    height: number

    constructor(rootDiv: string, format: ReadingTypes = 'jpeg', quality: number = 1.0) {
        this.dataHeader = 'data:image/svg+xml;charset=utf-8'
        this.svg = document.getElementById(rootDiv)!.querySelector('svg') as SVGSVGElement

        this.width = parseInt(window.getComputedStyle(this.svg).width)
        this.height = parseInt(window.getComputedStyle(this.svg).height)

        this.format = format
        this.quality = quality
    }

    destroyChildren(element: any) {
        while (element.firstChild) {
            const lastChild = element.lastChild ?? false
            if (lastChild) element.removeChild(lastChild)
        }
    }

    async loadImage(url: any): Promise<HTMLImageElement> {
        const $img = document.createElement('img')
        $img.src = url
        $img.width = this.width
        $img.height = this.height
        return new Promise((resolve, reject) => {
            $img.onload = () => resolve($img)
            $img.onerror = reject
        })
    }

    serializeAsXML(e: any) {
        return new XMLSerializer().serializeToString(e)
    }
    encodeAsUTF8(s: any) {
        return `${this.dataHeader},${encodeURIComponent(s)}`
    }
    encodeAsB64(s: any) {
        return `${this.dataHeader};base64,${btoa(s)}`
    }

    convertUrlToImage(url: any): Blob {

        const parts = url.split(','), mime = parts[0].match(/:(.*?);/)[1]
        if (parts[0].indexOf('base64') !== -1) {
            var bstr = atob(parts[1]), n = bstr.length, u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new Blob([u8arr], { type: mime })
        } else {
            var raw = decodeURIComponent(parts[1])
            return new Blob([raw], { type: mime })
        }

    }

    convertSVGtoImg = async (): Promise<Blob> => {

        const svgData = this.encodeAsUTF8(this.serializeAsXML(this.svg))

        const img = await this.loadImage(svgData)

        console.log('dimensions', img.width, img.height)

        console.log('The image', img.width, img.height)

        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx!.fillStyle = "#ffffff";
        ctx!.fillRect(0, 0, img.width, img.height);
        ctx!.drawImage(img, 0, 0, img.width, img.height)

        const dataURL = canvas.toDataURL(`image/${this.format}`, this.quality)

        return this.convertUrlToImage(dataURL)
    }
}