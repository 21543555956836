//@ts-ignore
import React, { useEffect, useRef, useState, useContext } from "react";
import * as d3 from "d3";
import Navigation from "../navigation";
import { DataManager } from '../utils/dataManager'
import { Oval } from 'react-loader-spinner'
// import Timer from 'react-timer-wrapper'
import { GenerateMapData } from './generateMapData'
import { customAlphabet } from 'nanoid'
import Timer from './timeKeeper'
import { ColorSchemeManager } from "./colorSchemeManager";
import { ColorScheme } from "../../types/interfaces";
import MindMap, { localData } from "./mindMap";
import { SvgToImage } from './svgToImage'
import DiagramModal from "../diagramModal";
import Camelot from '../utils/camelot'
import CamContext from '../utils/camelotContext'

const nanoid = customAlphabet('1234567890abcdef', 10)

function MindmapWrapper({ signOut, user }) {

    const context = useContext(CamContext)

    const json = { name: 'New Mind Map', items: [{ name: "New Node" }] }

    const generateMapData = new GenerateMapData(json)
    const map: any = generateMapData.generate()

    const [instructions, setInstructions] = useState('')
    const [mapData, setMapData] = useState(map)
    const [loading, setLoading] = useState(false)
    const [timeValue, setTimeValue] = useState(0)
    const [depthLevel, setDepthLevel] = useState(2)
    const [showAIInput, setShowAIInput] = useState(false)
    const [colorScheme, setColorScheme] = useState(Camelot.DEFAULT_COLOR_SCHEME)
    const [refresh, setRefresh] = useState(nanoid(8))
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        function isObject(obj) {
            return obj !== undefined && obj !== null && obj.constructor == Object;
        }

        if (context.drawing !== "" && context.drawing !== null) {
            if (!isObject(context.drawing)) {
                const json = JSON.parse(context.drawing)
                setMapData(json)
                setColorScheme(context.colorScheme)
            }
        }


    }, [context.drawing])

    const backgroundClass = () => {
        // const className = BBM.LocalStorage.get(BBM.Keys.BackgroundClass, 'default-background')
        // return `${className} react-target animate__animated animate__fadeIn`
        return `react-target animate__animated animate__fadeIn`
    }

    const captureInstructions = (event: any) => {
        setInstructions(event.target.value)
    }

    const showCreateMapInput = () => {
        setMapData(null)
        setShowAIInput(!showAIInput)
    }

    const createMap = async () => {
        if (instructions === '') {
            return
        }

        try {
            setLoading(true)
            setTimeValue(0)

            // await new Promise(r => setTimeout(r, 5000));

            const data: any = await DataManager.generateMindMap({ instructions })
            const json = JSON.parse(data)
            const generateMapData = new GenerateMapData(json)
            const mapdata: any = generateMapData.generate()
            setMapData(mapdata)
            setShowAIInput(false)
        }
        finally {
            setLoading(false)
        }
    }

    const clearMap = () => {
        context.clearDiagram()
        setShowAIInput(false)
        const generateMapData = new GenerateMapData(json)
        const map: any = generateMapData.generate()
        setMapData(map)
    }

    const showSaveModal = () => {
        if (context.drawing === "" || context.drawing === null) {
            setShowModal(true)
        }
        else {
            updateMap(context.diagramId)
        }
    }

    const cleanNodes = (nodes: any, list: any = []) => {

        nodes.map((x: any) => {
            list.push({ id: x.id, name: x.name, level: x.level, anchor: x.anchor, items: x.items })
            if (x.items !== undefined) {
                cleanNodes(x.items, list)
            }
        })

        return list

    }

    const cleanLinks = (links: any) => {
        return links.map((x: any) => {
            return { source: x.source.id, target: x.target.id, index: x.index, level: x.level }
        })
    }

    const updateMap = async (diagramId: string) => {

        try {

            setLoading(true)

            const svg2Image = new SvgToImage('maproot')

            const blob = await svg2Image.convertSVGtoImg()

            const drawing = {
                nodes: cleanNodes(localData.nodes),
                links: cleanLinks(localData.links)
            }

            await DataManager.modifyDrawing({ diagramId, drawing, image: blob, colorScheme })

            context.setDiagramDrawing(drawing)

        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }

    }

    const saveMap = async ({ diagramName = '', diagramDesc = '' }) => {

        Camelot.validateDiagramName(diagramName)

        try {

            setLoading(true)

            const svg2Image = new SvgToImage('maproot')

            const blob = await svg2Image.convertSVGtoImg()

            const drawing = {
                nodes: cleanNodes(localData.nodes),
                links: cleanLinks(localData.links)
            }

            const response = await DataManager.saveDrawing({ diagramName, diagramDesc, drawing, image: blob, instructions, diagramType: Camelot.DiagramTypes.MINDMAP, colorScheme })

            context.setDiagram({ id: response!.diagramId, name: diagramName, desc: diagramDesc, drawing, instructions })

        }
        finally {
            setLoading(false)
        }

    }

    const buttonDivClass = () => {
        if (showAIInput) {
            return "cell small-2 text-right save-button"
        }
        return "cell small-6 text-right save-button"
    }

    const updateColorScheme = (event) => {
        setColorScheme(event.target.value)
    }

    const getThemes = () => {
        const themes = ColorSchemeManager.getColorSchemes()

        const list: JSX.Element[] = [];

        list.push(<option key={0} value="">Choose Scheme</option>);

        let i = 1

        themes.map((x: ColorScheme) => {
            list.push(<option key={i} value={x.name}>{x.title}</option>)
            i++
        })

        return list
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <div className={backgroundClass()}>
            <Navigation signOut={signOut} user={user} showDiagramsOnly={true} />
            {loading ? <Oval
                ariaLabel="loading-indicator"
                height={100}
                width={100}
                strokeWidth={5}
                color="#A55640"
                secondaryColor="#efefef"
                wrapperClass="spinner"
            /> : ''}
            <div className="grid-container" style={{ marginTop: '12px' }}>
                <div className="grid-x">
                    <div className="cell small-10" style={{ margin: '0 auto' }}>
                        <div className="grid-x">
                            {showAIInput ?
                                <div className="cell small-9 save-button">
                                    <textarea id="instructions" className="float-left" style={{ minWidth: '400px', maxWidth: '630px', height: '140px' }} onChange={captureInstructions} placeholder="Tell the mindmap generator what you want to map out. To get started try something like: 'create a detailed outline of a book on harvesting mushrooms'" disabled={loading} value={instructions}></textarea>
                                    <button className="button small float-right" onClick={createMap} disabled={loading}>Build Map</button>
                                    <div className="counter-control">
                                        {loading ?
                                            <span style={{ width: "20px", marginRight: '7px', marginLeft: '7px', fontFamily: 'monospace', float: 'right' }}><Timer active={loading} /></span>
                                            : ''}
                                    </div>
                                </div>
                                : <div className="cell small-6 save-button"></div>}
                            <div className={buttonDivClass()}>
                                {showAIInput ? '' :
                                    <>
                                        <button className="button small button-margin" onClick={showCreateMapInput} disabled={loading}>AI Map Generator</button>
                                        <select onChange={updateColorScheme} value={colorScheme} disabled={loading} style={{ height: '34px', maxWidth: '150px', marginLeft: '7px', position: 'relative', top: '-7px' }}>
                                            {getThemes()}
                                        </select>
                                    </>
                                }
                                <button className="button small button-margin" onClick={clearMap} disabled={loading}>Clear Map</button>
                                <button className="button small button-margin" onClick={showSaveModal} disabled={loading}>Save Map</button>
                            </div>
                            {showAIInput ? '' :
                                <div className="cell small-12 text-center small" style={{ color: '#999', fontSize: '.8em' }}>
                                    Click to edit/highlight. Esc to abort edit. Shift+delete to remove highlighted node.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <MindMap mapData={mapData} visible={true} colorScheme={colorScheme} />
            <DiagramModal handleSave={saveMap} showModal={showModal} closeModal={closeModal} />
        </div>

    );
}

export default MindmapWrapper;