/* eslint-disable no-unused-vars */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './utils/init';
import 'foundation-sites/dist/css/foundation.min.css'
import 'animate.css'
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from "aws-amplify";
import config from './config';
import './App.scss'
import App from './App';
import $ from 'jquery'
import { BrowserRouter } from "react-router-dom"
import 'foundation-sites/dist/js/plugins/foundation.core'
import 'foundation-sites/dist/js/plugins/foundation.reveal'
import 'foundation-sites/dist/js/plugins/foundation.offcanvas'
import 'foundation-sites/dist/js/plugins/foundation.util.motion'
import 'foundation-sites/dist/js/plugins/foundation.util.triggers'
import 'foundation-sites/dist/js/plugins/foundation.util.touch'
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard'
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery'

//https://docs.amplify.aws/javascript/tools/libraries/configure-categories/

const params = {
    Auth: {
        Cognito: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolClientId: config.cognito.USER_POOL_CLIENT_ID
        }
    },
    Storage: {
        S3: {
            region: config.s3.REGION,
            bucket: config.s3.BUCKET,
            identityPoolId: config.cognito.IDENTITY_POOL_ID
        },
    },
    API: {
        REST:
        {
            "camelot": {
                region: config.apiGateway.REGION,
                endpoint: config.apiGateway.URL,
            }
        }
    }
}

Amplify.configure(params);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

$(document).foundation();