import { get, del, post, put } from 'aws-amplify/api'
import axios from 'axios'
import Camelot from './camelot'

export class DataManager {

    static async getDrawing({ id }) {
        const restOperation = get({ apiName: Camelot.Constants.APINAME, path: `/diagrams/${id}` })
        const { body } = await restOperation.response
        return await body.json()
    }

    static async getAllDrawings({ search }) {
        const restOperation = get({ apiName: Camelot.Constants.APINAME, path: `/diagrams?search=${search}` })
        const { body } = await restOperation.response
        return await body.json()
    }

    static async removeDrawing({ id }) {
        const restOperation = del({ apiName: Camelot.Constants.APINAME, path: `/diagrams/${id}` })
        const { body } = await restOperation.response
        return await body.json()
    }

    static async saveDrawing({ diagramName, diagramDesc, drawing, image, diagramType = Camelot.DiagramTypes.DIAGRAM, instructions = '', colorScheme = Camelot.DEFAULT_COLOR_SCHEME }) {
        const restOperation = post({
            apiName: Camelot.Constants.APINAME, path: '/diagrams', options: {
                body: { name: diagramName, description: diagramDesc, drawing, diagramType, instructions, colorScheme }
            }
        })

        const response = await restOperation.response

        try {

            const json = await response.body.json()

            // save thumbnail of diagram
            await axios.put(json.url, image, {
                headers: { "content-type": image.type }
            });

            return {
                diagramId: json.diagramId,
                diagramName: json.diagramName
            }
        }
        catch (err) {
            console.log('Thumbnail upload error-----------------------\n', err)
            throw new Error('Thumbnail upload error!')
        }
    }

    static async modifyDrawing({ diagramId, drawing, image, colorScheme = Camelot.DEFAULT_COLOR_SCHEME }) {

        const restOperation = put({ apiName: Camelot.Constants.APINAME, path: `/diagrams/${diagramId}`, options: { body: { drawing, colorScheme } } })

        const { body } = await restOperation.response
        const response = await body.json()

        try {
            // save thumbnail of diagram
            await axios.put(response.url, image, {
                headers: { "content-type": image.type }
            });
        }
        catch (err) {
            console.log('Thumbnail upload error-----------------------\n', err)
        }
    }

    static async renameDrawing({ diagramId, diagramName, diagramDesc }) {
        const restOperation = put({ apiName: Camelot.Constants.APINAME, path: `/rename/${diagramId}`, options: { body: { diagramName, diagramDesc } } })
        const { body } = await restOperation.response
        return await body.json()
    }

    static async generateCode({ image, targetType, instructions = '' }) {
        const restOperation = post({
            apiName: Camelot.Constants.APINAME,
            path: '/generate/code',
            options: { body: { image, targetType, instructions } }
        })
        const { body } = await restOperation.response
        return await body.json()
    }

    // static async saveComponent({ diagramName, diagramDesc, drawing, instructions }) {
    //     return await Amplify.API.post(Camelot.Constants.APINAME, '/component/save', {
    //         body: { diagramName, diagramDesc, drawing, instructions }
    //     })
    // }

    static async generateMindMap({ instructions }) {

        const restOperation = post({
            apiName: Camelot.Constants.APINAME,
            path: '/generate/map',
            options: { body: { instructions } }
        })
        const { body } = await restOperation.response
        return await body.json()
    }

}
