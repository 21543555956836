import React, { ReactNode, isValidElement } from "react"
import { createPortal } from 'react-dom';
import Parser from 'html-to-react';

export interface ComponentWrapperProps {
    source?: ReactNode;
    target: string
}

const ComponentWrapper: React.FC<ComponentWrapperProps> = (props) => {

    const convertToReact = (source: any) => {

        if (isValidElement(source)) {
            return source
        }

        return Parser.Parser().parse(source)
    }

    return (
        <>
            <div style={{ minWidth: '400px', margin: '0 auto', padding: '12px' }}>
                {createPortal(
                    convertToReact(props.source),
                    document.getElementById(props.target) as HTMLElement
                )}
            </div>
        </>
    )
}

export default ComponentWrapper